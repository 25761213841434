import React, { useState } from "react";
import { Stack } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  stack: {
    backgroundColor: "#27aae1",
    height: 30,
  },
});

export default function TopBar() {
  const classes = useStyles();
  const [colourIcon1, setColourIcon1] = useState("white");
  const [colourIcon2, setColourIcon2] = useState("white");

  function changeIcon1Black() {
    setColourIcon1("black");
  }

  function changeIcon1White() {
    setColourIcon1("white");
  }

  function changeIcon2Black() {
    setColourIcon2("black");
  }

  function changeIcon2White() {
    setColourIcon2("white");
  }

  return (
    <Stack
      className={classes.stack}
      direction="row"
      spacing={2}
      justifyContent="center"
      alignContent="center"
    >
      <IconButton
        href="https://www.linkedin.com/in/advance-crt-577502192/?originalSubdomain=ie"
        aria-label="Visit ADVANCE LinkedIn"
      >
        <LinkedInIcon
          sx={{ color: colourIcon1 }}
          onMouseEnter={() => changeIcon1Black()}
          onMouseLeave={() => changeIcon1White()}
        />
      </IconButton>
      <IconButton
        href="https://twitter.com/AdvanceCrt"
        aria-label="Visit ADVANCE Twitter"
      >
        <XIcon
          sx={{ color: colourIcon2 }}
          onMouseEnter={() => changeIcon2Black()}
          onMouseLeave={() => changeIcon2White()}
        />
      </IconButton>
    </Stack>
  );
}
